import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
//import FaLongArrowAltRight from 'react-icons/fa';
import * as classes from './BlogPostCard.module.css';

function BlogPostCard({
  companyName,
  title,
  logoSrc,
  link,
  featuredImageSrc,
  isLogoRound,
  featuredImageLoading,
  arrow,
}) {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "blog-posts/featured-images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const match = React.useMemo(
    () =>
      data.allFile.edges.find(
        ({node}) => `blog-posts/featured-images/${featuredImageSrc}` === node.relativePath
      ),
    [data, featuredImageSrc]
  );
  return (
    <a className={classes.link} href={link} target="_blank" rel="noopener noreferrer">
      <article className={`${classes.card}`}>
        <header className={classes.header}>
          <div className={classes.featuredImage}>
            <Img
              className={classes.featuredImage}
              imgStyle={{objectFit: 'cover'}}
              placeholderStyle={{objectFit: 'cover'}}
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={match.node.childImageSharp.fluid}
              alt={title}
              fadeIn={false}
              loading={featuredImageLoading}
            />
          </div>
          <div
            className={`${classes.logoContainer} ${
              isLogoRound ? classes.roundLogo : classes.rectLogo
            }`}
          >
            <img className={classes.logo} src={logoSrc} alt={companyName} />
          </div>
        </header>
        <div className={classes.body}>
          <div className={classes.title}>{title}</div>
          <a className={classes.link} href={link} target="_blank" rel="noopener noreferrer">
            Read More&nbsp;&nbsp;&nbsp;
            <img src={arrow} alt="" />
          </a>
        </div>
      </article>
    </a>
  );
}

BlogPostCard.propTypes = {
  companyName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoSrc: PropTypes.string.isRequired,
  featuredImageSrc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isLogoRound: PropTypes.bool,
  featuredImageLoading: PropTypes.oneOf(['lazy', 'eager']),
};

BlogPostCard.defaultProps = {
  isLogoRound: false,
  featuredImageLoading: 'eager',
};

export default BlogPostCard;
