import React from 'react';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlogPostCard from '../../BlogPostCard/BlogPostCard';
import stacksLogo from '../../../../assets/images/blog-posts/client-logos/stacks.png';
import chainlinkLogo from '../../../../assets/images/blog-posts/client-logos/chainlink.png';
import audioCardioLogo from '../../../../assets/images/blog-posts/client-logos/audio-cardio.png';
import camaradlyLogo from '../../../../assets/images/blog-posts/client-logos/camaradly.png';
import polkacityLogo from '../../../../assets/images/blog-posts/client-logos/polka.png';
import all3dLogo from '../../../../assets/images/blog-posts/client-logos/all3d.png';
import * as classes from './BlogPostsSection.module.css';
import Container from '../../Container/Container';
import CustomDot from '../../Carousel/CustomDot';
import arrowIcon from '../../../../assets/images/blog-posts/featured-images/long-arrow.png';

const responsiveValue = {
  lg: {
    breakpoint: {
      max: Number.MAX_SAFE_INTEGER,
      min: 1201,
    },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  md: {
    breakpoint: {
      max: 1200,
      min: 769,
    },
    items: 2,
    partialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  sm: {
    breakpoint: {
      max: 768,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
};

function BlogPostsSection({
  bodyClassName,
  carouselResponsive = responsiveValue,
  descriptionClassName,
}) {
  return (
    <section className={classes.section}>
      <Container>
        <div className={clsx(bodyClassName)}>
          <div className={classes.description}>Client Updates, News, and Wins</div>

          <div className={classes.postsContainer}>
            <Carousel
              additionalTransfrom={0}
              arrows={false}
              autoPlay
              autoPlaySpeed={6000}
              customTransition="transform 800ms ease-out"
              transitionDuration={800}
              centerMode={false}
              className=""
              itemClass={classes.carouselItem}
              draggable
              focusOnSelect={false}
              infinite
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside
              responsive={carouselResponsive}
              showDots
              customDot={<CustomDot color="#0ec47588" activeColor="#0ec475cc" />}
              slidesToSlide={2}
              swipeable
              ssr
            >
              <BlogPostCard
                companyName="Polkacity"
                title="Tintash partners with Polkacity to create a NFT Game design"
                featuredImageLoading="lazy"
                featuredImageSrc="polkacity.png"
                link="https://polkacity.medium.com/polkacity-nft-gaming-design-32c8c86deb19/"
                arrow={arrowIcon}
                logoSrc={polkacityLogo}
              />
              <BlogPostCard
                companyName="Stacks"
                title="The Stacks Blockchain API Now Supports Rosetta Standard"
                featuredImageLoading="lazy"
                featuredImageSrc="stacks.png"
                link="https://blog.blockstack.org/the-stacks-blockchain-api-now-supports-coinbases-rosetta-standard/"
                arrow={arrowIcon}
                logoSrc={stacksLogo}
              />
              <BlogPostCard
                companyName="Audio Cardio"
                title="Audio Cardio selected as finalist in Fast Company's World Changing Ideas Award"
                featuredImageLoading="lazy"
                featuredImageSrc="audio.png"
                link="https://www.fastcompany.com/90624520/world-changing-ideas-awards-2021-wellness-finalists-and-honorable-mentions"
                arrow={arrowIcon}
                logoSrc={audioCardioLogo}
              />
              <BlogPostCard
                companyName="Polkacity"
                title="Tintash partners with Polkacity to create a NFT Game design"
                featuredImageLoading="lazy"
                featuredImageSrc="polkacity.png"
                link="https://polkacity.medium.com/polkacity-nft-gaming-design-32c8c86deb19/"
                arrow={arrowIcon}
                logoSrc={polkacityLogo}
              />
              <BlogPostCard
                companyName="Stacks"
                title="The Stacks Blockchain API Now Supports Rosetta Standard"
                featuredImageLoading="lazy"
                featuredImageSrc="stacks.png"
                link="https://blog.blockstack.org/the-stacks-blockchain-api-now-supports-coinbases-rosetta-standard/"
                arrow={arrowIcon}
                logoSrc={stacksLogo}
              />
              <BlogPostCard
                companyName="Camaradly"
                title="Camaradly was featured on Product Hunt and was at the #1 spot"
                featuredImageLoading="lazy"
                featuredImageSrc="camaradly.png"
                link="https://www.producthunt.com/posts/camaradly"
                arrow={arrowIcon}
                logoSrc={camaradlyLogo}
              />
              <BlogPostCard
                companyName="All3D"
                title="All3D featured as a digital disruptor on the Business of Home podcast"
                featuredImageLoading="lazy"
                featuredImageSrc="all3d.jpg"
                link="https://businessofhome.com/articles/will-3d-modeling-change-the-design-industry-forever"
                arrow={arrowIcon}
                logoSrc={all3dLogo}
              />
            </Carousel>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default BlogPostsSection;
