import React from 'react';
import * as classes from './CustomDot.module.css';

const CustomDot = ({index, onClick, active, color = '#ffffff70', activeColor = '#ffffffec'}) => (
    <div
      style={{
        '--color': color,
        '--active-color': activeColor,
      }}
      role="button"
      onClick={e => {
        onClick();
        e.preventDefault();
      }}
      className={`${classes.dot} ${active ? classes.dotActive : ''}`}
    />
  );

export default CustomDot;
