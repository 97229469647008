import React from 'react';
import {container} from './Container.module.css';

function Container({children, ...props}) {
  return (
    <div {...props} className={`${container} ${props.className ?? ''}`}>
      {children}
    </div>
  );
}

export default Container;
