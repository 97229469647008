// extracted by mini-css-extract-plugin
export var arrow = "BlogPostCard-module--arrow--zs10f";
export var body = "BlogPostCard-module--body--hp7tZ";
export var card = "BlogPostCard-module--card--vATJk";
export var featuredImage = "BlogPostCard-module--featured-image--DprHx";
export var header = "BlogPostCard-module--header--mDrdV";
export var link = "BlogPostCard-module--link--B--7g";
export var logo = "BlogPostCard-module--logo--EPBxy";
export var logoContainer = "BlogPostCard-module--logo-container--AaMiY";
export var rectLogo = "BlogPostCard-module--rect-logo--BZbhJ";
export var roundLogo = "BlogPostCard-module--round-logo--pnIV0";
export var title = "BlogPostCard-module--title--FOSNg";